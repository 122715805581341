import React from 'react';
import { Card, Descriptions } from 'antd';
import { PhoneOutlined, MailOutlined, EnvironmentOutlined, WechatOutlined } from '@ant-design/icons';
import zhqy from '../../assets/img/zhqy.jpg';
import banner3 from '../../assets/img/banner3.jpg';
import style from './contact.module.scss';

export default function Contact() {

    const items = [
        {
            key: '1',
            label: <><PhoneOutlined className={style.icon} />电话</>,
            children: '16601153615',
        }, {
            key: '2',
            label: <><MailOutlined className={style.icon} />邮箱</>,
            children: 'zhqy20220815@163.com',
        }, {
            key: '3',
            label: <><EnvironmentOutlined className={style.icon} />地址</>,
            children: '河北省石家庄市高新区裕华东路453号创新大厦A座22层3206',
        }, {
            key: '4',
            label: <><WechatOutlined className={style.icon} />微信公众号</>,
            children: <img className={style.zhqy} src={zhqy} alt='zhqy' />,
        }
    ]

    return (
        <div className={style.pageContainer}>
            <div className={style.mainContainer}>
                <Card>
                    <div className={style.banner}>
                        <div className={style.slogan}>期待您的联系</div>
                        <img className={style.img} src={banner3} alt='banner3' />
                    </div>
                    <Descriptions bordered items={items} />
                </Card>
            </div>
        </div>
    )
}
