import React from 'react';
import { Card, Col, Row } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import style from './products.module.scss';
const { Meta } = Card;

export default function Products() {

    return (
        <div className={style.pageContainer}>
            <div className={style.mainContainer}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card
                            cover={
                                <div className={style.zhglpt}></div>
                            }
                            actions={[<a href='https://www.zhihuiqianyang.com:3001' target='_blank' rel='noopener noreferrer'><SendOutlined key='send' /></a>]}>
                            <Meta
                                title="智慧乾阳综合管理平台"
                                description="简介：基础平台，包含用户管理、组织管理、角色管理、菜单管理等。">
                            </Meta>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
