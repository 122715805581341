import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom';
import logo from './assets/img/logo.png';
import style from './assets/scss/app.module.scss';
function App() {

  const navigate = useNavigate();

  const location = useLocation();

  const handleClick = () => {
    navigate("/home");
  }

  return (
    <div className={style.pageContainer}>
      <div className={style.headerContainer}>
        <div className={style.logoContainer}>
          <img className={style.logo} src={logo} alt='logo' onClick={handleClick} />
        </div>
        <div className={style.navigateContainer}>
          <ul>
            <li><NavLink to={'/home'} className={({ isActive }) => isActive ? style.active : location.pathname === '/' ? style.active : ''}>首页</NavLink></li>
            <li><NavLink to={'/products'} className={({ isActive }) => isActive ? style.active : ''}>产品中心</NavLink></li>
            <li><NavLink to={'/about'} className={({ isActive }) => isActive ? style.active : ''}>关于我们</NavLink></li>
            <li><NavLink to={'/contact'} className={({ isActive }) => isActive ? style.active : ''}>联系我们</NavLink></li>
          </ul>
        </div>
      </div>
      <div className={style.contentContainer}>
        <Outlet />
      </div>
      <div className={style.footerContainer}>
        版权所有 © 河北智慧乾阳有限公司 2022-2023。保留一切权利。<a href="https://beian.miit.gov.cn/" target="_blank" rel='noopener noreferrer'>冀ICP备2023027058号-1</a>
      </div>
    </div>
  );
}

export default App;
