import App from '../App';
import Home from '../pages/Home';
import Products from '../pages/Products';
import About from '../pages/About';
import Contact from '../pages/Contact';

const routers = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                element: <Home />,
            }, {
                path: '/home',
                element: <Home />,
            }, {
                path: '/products',
                element: <Products />,
            }, {
                path: '/about',
                element: <About />,
            }, {
                path: '/contact',
                element: <Contact />,
            }
        ]
    }
]

export default routers;
