import React from 'react';
import style from './home.module.scss';

export default function Home() {
    return (
        <div className={style.pageContainer}>
            <div className={style.slogan}>科技创造未来</div>
            <div className={style.secondarySlogan}>专注于为中小企业发展提供一站式服务平台</div>
        </div>
    )
}
