import React from 'react';
import { Card, Carousel } from 'antd';
import banner1 from '../../assets/img/banner1.jpg';
import banner2 from '../../assets/img/banner2.jpg';
import style from './about.module.scss';

export default function About() {

    return (
        <div className={style.pageContainer}>
            <div className={style.mainContainer}>
                <Card>
                    <Carousel autoplay={true} effect={'fade'}>
                        <div className={style.banner}>
                            <div className={style.slogan}>诚信为本，服务至上</div>
                            <img src={banner1} alt='banner1' />
                        </div>
                        <div className={style.banner}>
                            <div className={style.slogan}>诚信为本，服务至上</div>
                            <img src={banner2} alt='banner2' />
                        </div>
                    </Carousel>
                    <p>
                        河北智慧乾阳科技有限公司成立于2022年8月，旨在通过先进的技术和不断创新的精神，为客户提供优质、高效、具有前瞻性的解决方案。自成立以来，公司一直专注于科技领域的研发与应用，逐渐发展成为国内知名的科技企业。
                    </p>
                    <p>
                        主要业务主要为B/S软件开发、小程序开发、手机端开发、公众号开发。公司通过运用最前沿的技术，为各行各业的客户提供全面的解决方案。同时，公司还致力于为客户提供定制化服务，以满足客户的个性化需求。
                    </p>
                    <p>
                        我们拥有一支高素质的研发团队，具备强大的技术实力和创新能力。随着公司不断投入研发，时刻保持在技术领域的领先地位，为客户持续提供优质的产品和服务。
                        经过多年的积累，公司已经具备了丰富的行业经验。深入了解不同行业的需求，能够准确把握市场趋势，为客户提供符合实际需求的解决方案。
                        公司在业界树立了良好的口碑和品牌形象。我们以诚信为本，注重服务质量，与客户建立了长期稳定的合作关系，赢得了客户的信任和支持。
                        我们将继续保持对技术研发的投入，不断拓展新的业务领域。致力于为客户提供更加优质、高效、具有前瞻性的解决方案。
                    </p>
                </Card>
            </div>
        </div>
    )
}
